<div class="client-comments-section">
    <div class="container-fluid">
      <div class="d-flex justify-content-center">
        <h2 class="comment-section-heading">What they say about us</h2>
      </div>
      <div class="client-comment-content">
        <div class="carousel-wrapper">
          <div class="carousel-container">
            <div class="image-row" #carouselDiv>
              <div class="card-gap" *ngFor="let setarray of reviewContent; let i = index">
                <app-client-comment-card [eachReviewContent]="setarray"></app-client-comment-card>
              </div>
            </div>
          </div>
          <!-- Indicators -->
          <div class="carousel-buttons">
            <button class="carousel-control-prev" type="button" (click)="prevSlide()">
              <fa-icon [icon]="['fas', 'left-long']" class="arrow-font"></fa-icon>
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <div class="carousel-indicators">
              <button type="button" *ngFor="let item of reviewContent; let i = index"
                [class.active]="i === activeIndex"
                (click)="selectSlide(i)"></button>
            </div>
            <button class="carousel-control-next" type="button" (click)="nextSlide()">
              <fa-icon [icon]="['fas', 'right-long']" class="arrow-font"></fa-icon>
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  