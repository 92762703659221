[
    {
        "client_image":"IET-logo.png",
        "client_name":"Sara Sharman",
        "client_designation":"Head of Content Services, The IET",
        "client_comment":"ReView’s refreshingly simple user interface, combined with River Valley’s intimate knowledge of scholarly content, gave us the confidence to choose River Valley over the competition.",
        "client_link":"https://www.theiet.org/"
    },
    {
        "client_image":"APS-Logo-1.png",
        "client_name":"Mark Doyle",
        "client_designation":"Chief Information Officer, American Physical Society",
        "client_comment":"RVPublisher is a sophisticated XML/MathML content editing system that allows the editorial staff of Physics to rapidly create, update, and publish articles with a push of a button while maintaining tight deadlines and without concern about the underlying markup. Working with RVT, we have been able to finely tailor the system to our very specific technical requirements and workflow.",
        "client_link":"https://www.aps.org/"
    },
    {
        "client_image":"BioRxiv-Logo.png",
        "client_name":"John Inglis",
        "client_designation":"Co-founder, BioRxiv",
        "client_comment":"We are delighted that this most recent integration between submission systems extends the options available to authors of scientific papers. The transfer of manuscripts into and out of bioRxiv is an immensely popular feature of the server and it has been a pleasure to work with River Valley’s team to bring the underlying technologies to even more journals.",
        "client_link":"https://www.biorxiv.org/"
    },
    {
        "client_image":"ORCID_logo.png",
        "client_name":"Alice Meadows",
        "client_designation":"Former Director of Community Engagement & Support, ORCID",
        "client_comment":"The implementation of ORCID’s review functionality in River Valley’s platform gives reviewers an easy way to get credit for their work, while retaining full control of what they want uploaded to ORCID. Thanks to River Valley for setting up this integration.",
        "client_link":"https://orcid.org/"
    },
    {
        "client_image":"gigascience_logo.png",
        "client_name":"Scott Edmunds",
        "client_designation":"Executive Editor, GigaScience",
        "client_comment":"In our seven years of pushing the boundaries of scientific publishing, our biggest frustration has been trying to work around legacy publishing obstacles. Working with River Valley Technologies – with their state-of-the-art, forward-thinking platforms – will finally allow us to put all our new ideas into practice.",
        "client_link":""
    },
    {
        "client_image":"npg_logo.png",
        "client_name":"Donald McDonald",
        "client_designation":"Global Head of Production, Nature Journals",
        "client_comment":"River Valley has provided core production services (on-shore copy editing, typesetting, artwork preparation, content proofing, supply of electronic deliverables for web and print) to the Nature Research Journals for over 10 years … they have consistently delivered competitive turnaround times and high quality.",
        "client_link":"https://www.nature.com/"
    },
    {
        "client_image":"sphere.png",
        "client_name":"Tristan Hale",
        "client_designation":"Head of Digital and Communications, Sphere",
        "client_comment":"If you have semi-structured content – like humanitarian standards – to be made available both digitally and in print, consider creating the digital version first. RVT’s ‘XML-first’ publishing approach lets you do just this, and structuring your content early opens a range of possibilities.",
        "client_link":"https://www.spherestandards.org/"
    }
]