import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ContactService } from '../services/contact.service';
import { ActivatedRoute } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrl: './contact-modal.component.scss'
})
export class ContactModalComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  countries: any[] = [];
  surgicalSpecialties: any[] = [];
  contactmodalForm: FormGroup;
  submitted = false;
  private existingEmails: string[] = []; // Store existing emails
  success: boolean = false;;
  falied: boolean = false;
  products = ['Submission & Peer review', 'Research integrity', 'Composition', 'Hosting', 'Data analytics and reporting', 'End to end publishing']
  urlSegments: any;
  lastSegment = '';
  matchingProduct = '';
  submittedbutFailed = false;
  siteKey = environment.recaptcha.siteKey;
  validationMessages = {
    NAME: {
      required: 'Name is required.',
      pattern: 'Enter a valid name.',
    },
  };
  constructor(private http: HttpClient, private fb: FormBuilder, private contactService: ContactService,
    private route: ActivatedRoute, private recaptchaService: ReCaptchaV3Service) {
    this.contactmodalForm = this.fb.group({
      NAME: ['', [Validators.required, Validators.pattern(/^[A-Za-z]{2,}( [A-Za-z]{2,})*$/),]],
      EMAIL: ['', [Validators.required, Validators.email, Validators.pattern(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      )]],
      ORGANISATION: ['', Validators.required],
      QUERY: ['', Validators.required],
      PRODUCT_DEMO: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.urlSegments = this.route.snapshot.url;
    if (this.urlSegments.length > 0) {
      this.lastSegment = this.urlSegments[this.urlSegments.length - 1].path;
      this.matchingProduct = this.lastSegment = this.lastSegment.toLowerCase().replace(/-/g, ' ');
      this.contactmodalForm.controls['PRODUCT_DEMO'].setValue(this.matchingProduct);


    }
  }


  onSubmit(action: string) {

    this.submitted = true;

    if (this.contactmodalForm.valid) {
      this.recaptchaService.execute(action).subscribe((token: string) => {
       
        // console.log("iam here");

        // console.log('reCAPTCHA token:', token);
        // Send this token to your backend for verification
        if (token) {
          this.submittedbutFailed = false;

          const formData = {
            ...this.contactmodalForm.value, // Include form inputs
            recaptchaToken: token,    // Add the reCAPTCHA token
          };
          // Optional: Send to mainService as JSON data if required
          this.contactService.contactRVT(formData).subscribe(
            (response: any) => {
              if (response.status === 200) {
                this.success = true;
                // Optionally reset and close modal after success
                setTimeout(() => {
                  this.contactmodalForm.reset();
                  // this.closeModal();
                }, 2000);
              }
              else if (response.status === 400 ) {
                this.falied = true;
                this.submitted = false
                // this.contactmodalForm.get('EMAIL')?.setErrors({ emailTaken: true });
              }
            },
            (error) => {
              // console.error('Error in mainService request', error);
            }
          );
        }
        else {

          // this.success = false
          // this.falied = true;
        }

      });



    }
    else {
      this.submittedbutFailed = true;
      setTimeout(() => {
        // this.falied = true;;
        // this.success=false;
        this.submitted = false;
      }, 5000);
    }

  }
  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  closeModal() {
    this.close.emit();
  }
  // resolved(captchaResponse: string | null): void {
  //   if (captchaResponse) {
  //     console.log(`Captcha resolved with response: ${captchaResponse}`);
  //     // Handle the valid captcha response here
  //   } else {
  //     console.log('Captcha response is null');
  //     // Optionally handle the null case here
  //   }
  // }


}
