import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import * as pageKeyword from '../../assets/json/keywords.json'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  isModalOpen = false;
  homepageKeywordData: any[] = (pageKeyword as any).default.homepage
  constructor(private title: Title,private meta: Meta) {
    this.title.setTitle('River Valley Technologies');
    this.meta.updateTag({ name: 'keywords', content: this.homepageKeywordData.join(', ') });
  }
  openModal() {
    this.isModalOpen = true;
  }
  closeJoinModal() {
    this.isModalOpen = false;
  }
}
