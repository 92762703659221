import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './template/header/header.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FooterComponent } from './template/footer/footer.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { ClientCommentComponent } from './client-comment/client-comment.component';
import { PageContentComponent } from './page-content/page-content.component';
import {  provideHttpClient, withFetch } from '@angular/common/http';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ClientCommentCardComponent } from './client-comment-card/client-comment-card.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha'
import { environment } from '../environments/environment';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ImageCarouselComponent,
    ClientCommentComponent,
    PageContentComponent,
    PageNotFoundComponent,
    ClientCommentCardComponent,
    NewsDetailsComponent,
    ContactModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RecaptchaModule
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch()),
    { provide: RECAPTCHA_V3_SITE_KEY,  useValue: environment.recaptcha.siteKey, }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary,){
    this.library.addIconPacks(fas, far, fab);
  }
 }
