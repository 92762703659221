import { Component } from '@angular/core';
import { ScrollService } from './../../services/scroll.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  isAboutMenuOpen = false;
  isProductsMenuOpen = false;
  isResourcesMenuOpen = false;
  isModalOpen = false;
  constructor(private scrollService: ScrollService) { }
  scrollToSection() {
    this.scrollService.scrollToSection('targetSection');
  }
  openModal() {
    this.isModalOpen = true;
  }
  closeJoinModal() {
    this.isModalOpen = false;
  }
}
