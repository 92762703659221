<footer>
    <div class="footer-section">
        <div class="d-flex justify-content-between pt-5 pb-3 flex-sm-column flex-md-row">
            <div class=" ">
                <h5 class="company-name">River Valley Technologies Limited </h5>
                <div class="mt-3">
                    <span class="details"><strong class="special">Registered office:</strong> 5 Giffard Court
                    </span><br>
                    <span class="details"> Millbrook Close, Northampton, NN5 5JF,
                    </span><br>
                    <span class="details">United Kingdom
                    </span><br>
                    <span class="details"> Registered in England & Wales No. 05219729</span>
                </div>
            </div>
            <div class=" d-xs-none d-sm-none d-md-none  d-lg-none d-xl-block d-xxl-block">
                <div class="d-flex flex-column justify-content-center fit-content">
                    <div class="log-img d-flex justify-content-center">
                        <img src="rvt_logo.png" alt="" class="rvt_logo">
                    </div>
                    <span class="special pt-3">
                        <strong>Copyright</strong> © <strong>2024 River Valley Technologies Limited. <br/> All Rights Reserved</strong>
                    </span>
                </div>
            </div>
            <div class="small-screen-padding">
                <!-- <div class="row ">-->
                <div class="d-flex justify-content-center  small-link-align">
                    <div class=" ">
                        <h5 class="link-heading special get-konw"><strong>Get to know us</strong></h5>
                        <div class="">
                            <ul class="ul-style first-ul">
                                <!-- <li><a class="lonkContent" routerLink="/page/about">About BJS Foundation</a></li> -->
                                <li class="pt-2">
                                    <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse"
                                        data-bs-target="#aboutMenu" role="button" aria-expanded="false"
                                        aria-controls="aboutMenu">
                                        About <fa-icon class="ps-2" [icon]="isAboutMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
                                    </a>
                                    <ul id="aboutMenu" class="collapse" (shown.bs.collapse)="isAboutMenuOpen = true"
                                        (hidden.bs.collapse)="isAboutMenuOpen = false">
                                        <li>
                                            <a class="lonkContent" routerLink="about">
                                                About us
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="about/our-people">
                                                Our people
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="about/careers">
                                                Careers
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="about/our-clients-and-partners">
                                                Clients and Partners
                                            </a>
                                        </li>

                                    </ul>
                                <li class="pt-2">
                                    <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse"
                                        data-bs-target="#productsMenu" role="button" aria-expanded="false"
                                        aria-controls="productsMenu">
                                        Products
                                        <fa-icon class="ps-2"
                                            [icon]="isProductsMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
                                    </a>
                                    <ul id="productsMenu" class="collapse"
                                        (shown.bs.collapse)="isProductsMenuOpen = true"
                                        (hidden.bs.collapse)="isProductsMenuOpen = false">
                                        <li>
                                            <a class="lonkContent"
                                                routerLink="products/submission-&-peer-review">
                                                Submission and Peer review
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="products/research-integrity">
                                                Research integrity
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="products/composition">
                                                Composition
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="products/hosting">
                                                Hosting
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="products/data-analytics-and-reporting">
                                                Data analytics and reporting
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="products/end-to-end-publishing">
                                                End-to-end publishing
                                            </a>
                                        </li>
                                    </ul>
                                <li class="pt-2">
                                    <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse"
                                        data-bs-target="#resourcespMenu" role="button" aria-expanded="false"
                                        aria-controls="resourcespMenu">
                                        Resources
                                        <fa-icon class="ps-2"
                                            [icon]="isResourcesMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
                                    </a>
                                    <ul id="resourcespMenu" class="collapse"
                                        (shown.bs.collapse)="isResourcesMenuOpen = true"
                                        (hidden.bs.collapse)="isResourcesMenuOpen = false">
                                        <li>
                                            <a class="lonkContent" routerLink="resources/onboarding-&-implementation">
                                                Our Onboarding and Implementation
                                            </a>
                                        </li>
                                        <li>
                                            <a class="lonkContent" routerLink="resources/client-services">
                                                Client services
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                
                                <li class="pt-2">
                                    <a class="lonkContent"  (click)="openModal()">Contact</a>
                                </li>
                            </ul>
                            <!-- <a class="menu-link" routerLink="/about">About us</a> -->
                        </div>
                        <div class="p-2 ps-0 link-heading special get-konw">
                            Connect with us 
                            <a  href="https://www.linkedin.com/company/river-valley-technologies/" target="_blank" class="ps-2 icon-link" title="Linkedin"> 
                                <fa-icon [icon]="['fab','linkedin']" class="linkedin-icon"></fa-icon>
                            </a>
                            <a  href="https://twitter.com/RiverValley1000" target="_blank" class="ps-2 icon-link" title="X"> 
                                <fa-icon [icon]="['fab','x-twitter']" class="linkedin-icon"></fa-icon>
                            </a>
                        </div>
                    </div>

                </div>
                <!-- <div class="pb-2"><a fragment="targetSection" class="menu-link" routerLink=""
                                (click)="scrollToSection(); $event.preventDefault()">What we do</a></div>
                        <div class="pb-2"><a href="" class="menu-link"
                                routerLink="about/our-clients-and-partners">Clients</a></div> -->
                <!-- </div> -->
                <!-- <div class="col-md-6 mt-2">
                        <div class="pb-2"><a href="" class="menu-link" routerLink="resources/news">News</a></div>
                        <div class="pb-2"><a href="" class="menu-link">Contact</a></div>
                    </div> -->
                <!-- <div class="col-md-12 mb-3"> -->

                <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
        <div class="row">
            <div
                class=" col-md-12 col-sm-12 d-xs-flex d-sm-flex d-md-flex d-lg-flex d-xl-none d-xxl-none justify-content-center">
                <div class="d-flex flex-column justify-content-center fit-content">
                    <div class="log-img d-flex justify-content-center">
                        <img src="rvt_logo.png" alt="" class="rvt_logo">
                    </div>
                    <div class="special pt-2 d-flex justify-content-center">
                        <p class="copyright">Copyright <span>©</span> 2024 River Valley Technologies Limited. <br/>All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-contact-modal *ngIf="isModalOpen"  (close)="closeJoinModal()"></app-contact-modal>