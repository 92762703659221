import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as newsData from '../../assets/json/news_content.json';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
  newsItem: any;
  newsContent: any[] = (newsData as any).default.news;
  safeContent: SafeHtml | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const title = this.route.snapshot.paramMap.get('title') ?? "";
    // const formattedTitle = this.formatted_title(title);
    
    this.newsItem = this.newsContent.find(e => e.news_link === title);
    if (!this.newsItem) {
      // Redirect to "Page Not Found" if title is invalid
      this.router.navigate(['/page-not-found']);
    } else {
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.newsItem.news_full_content);
    }
  }

  formatted_title(title: string): string {
    return title.replace(/-/g, ' ');
  }
}
