{
    "homepage":[
        "Research Publishing Solutions","End-to-End Publishing Platform","XML-first Publishing Workflow","Scholarly Publishing Technology",
        "Research Integrity Checks","Peer Review System","Automated File Conversion","Author Collaboration Tools","Publishing Hosting Platform",
        "Data Analytics for Publishers","STM Publishing Solutions","Academic Publishing Software","Modular Publishing Platform",
        "Research Communication Technology","XML-based Publishing","Research Integrity", "How River Valley Technologies is transforming scholarly publishing"
    ],
    "aboutpage":[
        "River Valley Technologies","Publishing Solutions","Research to Publication","XML-based Publishing","End-to-End Publishing System",
        "Efficient Research Communication","Publishing Workflow Innovation","Collaborative Publishing Solutions","Cloud-Based Publishing Platforms",
        "Automated Peer Review Management","Scholarly Publishing Technology","Integrity in Research Publishing","Customizable Publishing Workflows",
        "Experienced Publishing Team","Publishing Industry Leaders","Long-Term Client Partnerships","Publishing Technology Innovations",
        "Research Publication Efficiency","Open Access Publishing Solutions","Real-Time Publishing Dashboards","Peer Review Tools",
        "Content Management Systems for Publishers","User-Friendly Submission Interfaces","Data Integrity in Publishing","Flexible Publishing Ecosystem",
        "Digital Publishing Expertise","Academic Publishing Services","Historical Publishing Innovations","Publishing Technology Heritage","Trusted Publishing Partner",
        "How River Valley Technologies is transforming scholarly publishing", "Advantages of XML-first publishing solutions","Partnering with River Valley Technologies for publishing success",
        "Innovative tools for academic publishing workflows","Experience in automated peer review management systems","Efficient transition from research to publication with RVT",
        "The importance of research integrity in publishing","Building effective partnerships in the scholarly publishing ecosystem","The future of research communication with River Valley Technologies",
        "How RVT supports STM publishers and NGOs"
    ],
    "productspage":[
        "Review 3.0","Intuitive Submission System","Scalable Peer Review Solution","Seamless Journal Management","Mobile-Friendly Submission","Real-Time Data Dashboards",
        "Customizable Publishing Workflow","Drag-and-Drop File Upload","LaTeX File Handling","Unified Access for Publishers","Advanced Reporting Features",
        "Research Integrity Checker","Citation and Reference Analysis","Automated Content Checks","Statistical Pattern Detection","Content Authenticity Verification",
        "Image Duplication Detection","Publishing Automation Services","XML Document Processing","Content Editing Platform","Automated PDF Generation",
        "Multimedia Publishing Support","Flexible Hosting Solutions","Open Access Compliance","Data Visualization Tools","Advanced Data Analytics","Interactive Dashboard for Publishers",
        "Integrated Publishing System","Publishing Workflow Optimization","Best submission and peer review software for journals","How to optimize your publishing workflow",
        "Advantages of automated document processing for publishers","Improving research integrity in scholarly publishing","Key features of effective peer review platforms",
        "Benefits of customizable publishing solutions","How to handle LaTeX files in submission systems","Managing submission workflows with real-time dashboards",
        "Transitioning to an automated publishing system","Ensuring compliance with open access publishing standards"
    ],
    "resourcespage":[
        "Onboarding and Implementation","Effortless Onboarding","Seamless Transition","Flexible Publishing Workflow","Content Conversion Services",
        "Legacy Data Migration","Data Integrity Preservation","Historical Content Transfer","Customizable Publishing Solutions","Data Import Services",
        "Fast Data Retrieval","Comprehensive Content Reporting","Exceptional Client Service","Dedicated Project Manager","24/7 Customer Support",
        "Automated PDF Conversion","LaTeX File Debugging","XML File Management","Reference Style Expertise","Continuous Development in Publishing",
        "Rapid Feature Deployment","Collaborative Client Partnerships","Automated Composition Services","Robust Security Standards","Data Security Measures",
        "Business Continuity Planning","Reputable Server Hosting","User-Centric Development","Publishing Requirements Compliance","Publishing Industry Expertise",
        "How to smoothly onboard a publishing platform","Best practices for data migration in publishing","Importance of data integrity in publishing",
        "Customized solutions for publishers","Automated processes for publishing efficiency","Client-focused publishing services","Effective legacy data management",
        "Reliable support for scholarly publishing","Enhancing user experience in publishing systems","Top features for publishing software systems"
    ]
}