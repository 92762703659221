<div class="container-fluid custom-main-padding" #navbarContainer>
	<nav class="navbar navbar-expand-lg navbar-fixed-top p-0">
		<div class="container-fluid pe-0 ps-0">
			<div class="navbar-brand pl-5 pr-5 rvt-site-nav-bar">
				<img id="logo" src="rvt_logo.png" routerLink="/" class="logo-image" alt="">
			</div>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
				aria-label="Toggle navigation" (click)="toggleMobileView()">
				<span *ngIf="!isMenuOpen" class="navbar-toggler-icon">

					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px"
						height="24px">

						<path d="M3 6h18M3 12h18M3 18h18" />
					</svg>
				</span>
				<span *ngIf="isMenuOpen">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px"
						height="24px">
						<path d="M0 0h24v24H0V0z" fill="none" />
						<path
							d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7a.996.996 0 1 0-1.41 1.41L10.59 12l-4.88 4.88a.996.996 0 1 0 1.41 1.41L12 13.41l4.88 4.88a.996.996 0 1 0 1.41-1.41L13.41 12l4.88-4.88a.996.996 0 0 0 0-1.41z" />
					</svg>
				</span>
			</button>
			<div class="collapse navbar-collapse">
				<ul class="navbar-nav me-5 mb-2 mb-lg-0 custom-navbar">
					<li class="nav-item dropdown" (mouseleave)="closeMenu('about')">
						<a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
							aria-expanded="false" routerLinkActive="nav-link-active-link"
							(mouseenter)="cancelCloseMenu('about')" routerLink="about">
							About
							<fa-icon [icon]="['fas','angle-down']" *ngIf="!menuStates['about']"></fa-icon>
							<fa-icon [icon]="['fas','angle-up']" *ngIf="menuStates['about']"></fa-icon>
						</a>
						<ul class="dropdown-menu " (mouseleave)="closeMenu('about')">
							<li class="menu-items "><a class="dropdown-item " routerLink="about/our-people"
									(click)="closeMenu('about')">
									Our people
								</a>

								<!-- <ul class="dropdown-menu border-0 ps-2" (mouseleave)="closeMenu('about')">
									<li class="menu-items main-items"><a class="dropdown-item"  (click)="closeMenu('about')  ">
											Our team
										</a>
									</li>
									<li class="menu-items main-items"><a class="dropdown-item"  (click)="closeMenu('about')  ">
											Kaveh & Maryam
										</a>
									</li>
								</ul> -->
							</li>

							<li class="menu-items main-items">
								<a class="dropdown-item" routerLink="about/our-clients-and-partners"
									(click)="closeMenu('about')">
									Clients and Partners
								</a>
							</li>
							<li class="menu-items main-items">
								<a class="dropdown-item" routerLink="about/careers"
									(click)="closeMenu('about');">Careers</a>
							</li>

							<!-- <li class="menu-items">
								<hr class="dropdown-divider">
							</li>
							<li class="menu-items"><a class="dropdown-item" href="#">Something else here</a></li> -->
						</ul>
					</li>
					<li class="nav-item dropdown" (mouseleave)="closeMenu('products')">
						<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
							aria-expanded="false" routerLinkActive="nav-link-active-link"
							(mouseenter)="cancelCloseMenu('products')">
							Products
							<fa-icon [icon]="['fas','angle-down']" *ngIf="!menuStates['products']"></fa-icon>
							<fa-icon [icon]="['fas','angle-up']" *ngIf="menuStates['products']"></fa-icon>

						</a>
						<ul class="dropdown-menu" (mouseleave)="closeMenu('products')">
							<li class="menu-items main-items"><a class="dropdown-item"
									routerLink="products/submission-and-peer-review" (click)="closeMenu('products');">
									Submission and Peer review
								</a></li>
							<li class="menu-items main-items"><a class="dropdown-item"
									routerLink="products/research-integrity" (click)="closeMenu('products');">
									Research integrity
								</a></li>
							<li class="menu-items main-items"><a class="dropdown-item" routerLink="products/composition"
									(click)="closeMenu('products')">
									Composition
								</a></li>
							<li class="menu-items main-items"><a class="dropdown-item" routerLink="products/hosting"
									(click)="closeMenu('products')">
									Hosting
								</a></li>
							<li class="menu-items main-items"><a class="dropdown-item"
									routerLink="products/data-analytics-and-reporting" (click)="closeMenu('products');">
									Data analytics and reporting
								</a></li>
							<li class="menu-items main-items"><a class="dropdown-item"
									routerLink="products/end-to-end-publishing" (click)="closeMenu('products');">
									End-to-end publishing
								</a></li>

						</ul>
					</li>

					<li class="nav-item dropdown" (mouseleave)="closeMenu('resources')">
						<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
							aria-expanded="false" routerLinkActive="nav-link-active-link"
							(mouseenter)="cancelCloseMenu('resources')">
							Resources
							<fa-icon [icon]="['fas','angle-down']" *ngIf="!menuStates['resources']"></fa-icon>
							<fa-icon [icon]="['fas','angle-up']" *ngIf="menuStates['resources']"></fa-icon>
						</a>
						<ul class="dropdown-menu" (mouseleave)="closeMenu('products')">
							<!-- <li class="menu-items main-items"><a class="dropdown-item" routerLink="resources/news" (click)="closeMenu('resources');">
								News
								</a></li> -->
							<li class="menu-items main-items"><a class="dropdown-item"
									routerLink="resources/onboarding-and-implementation"
									(click)="closeMenu('resources');">
									Onboarding and Implementation
								</a></li>
							<li class="menu-items main-items"><a class="dropdown-item"
									routerLink="resources/client-services" (click)="closeMenu('resources');">
									Client services
								</a></li>


						</ul>
					</li>
					<li class="nav-item">
						<a class="nav-link" routerLink="news">
							News
						</a>

					<li class="nav-item">
						<button class="btn btn-outline-success me-2 uppercase" type="button"
							(click)="openModal()">Contact</button>
					</li>
					<!-- <li class="nav-item">
						<button class="btn btn-success " type="button">Book a demo</button>
					</li> -->
				</ul>
			</div>
			<div *ngIf="mobileView" class="collapse navbar-collapse nav-mobile nav-item-mobile scroll-menu"
				id="navbarSupportedContent">
				<ul class="navbar-nav custom-navbar-mobile">
					<li class="nav-item nav-item-mobile">
						<a class="nav-link dropdown-toggle sub" (click)="toggleDropdown(0)" role="button">
							About
							<fa-icon [icon]="['fas','angle-down']" *ngIf="!openDropdowns[0]"></fa-icon>
							<fa-icon [icon]="['fas','angle-up']" *ngIf="openDropdowns[0]"></fa-icon>
							<!-- <span class="custom-icon" [ngClass]="{'rotate': openDropdowns[0]}"></span> -->
						</a>
						<ul class="dropdown-menu custom-border academy-dropdown" [class.show]="openDropdowns[0]">
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="about" (click)="toggleMobileView()">About us</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="about/our-people" (click)="toggleMobileView()">
									<!-- (click)="toggleSubDropdown(0, 0)" -->
									Our people
									<span class="custom-icon" [ngClass]="{'rotate': isSubDropdownOpen(0, 0)}">

									</span></a>
								<!-- <ul class="dropdown-menu custom-border academy-dropdown"
									[class.show]="openSubDropdowns[0][0]">
									<li class="nav-item nav-item-mobile">
										<a class="dropdown-item" routerLink="about/our-people"
											(click)="toggleMobileView()">Our people</a>
									</li>
									<li class="nav-item nav-item-mobile">
										<a class="dropdown-item"  (click)="toggleMobileView()">Our team</a>
									</li>
									<li class="nav-item nav-item-mobile">
										<a class="dropdown-item"  (click)="toggleMobileView()">Kaveh &
											Maryam</a>
									</li>
								</ul> -->
							</li>

							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="about/our-clients-and-partners"
									(click)="toggleMobileView()">Clients and Partners</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="about/careers"
									(click)="toggleMobileView()">Careers</a>
							</li>
						</ul>
					</li>
					<li class="nav-item nav-item-mobile">
						<a class="nav-link dropdown-toggle sub" (click)="toggleDropdown(1)" role="button">
							Products
							<fa-icon [icon]="['fas','angle-down']" *ngIf="!openDropdowns[1]"></fa-icon>
							<fa-icon [icon]="['fas','angle-up']" *ngIf="openDropdowns[1]"></fa-icon>
							<!-- <span class="custom-icon" [ngClass]="{'rotate': openDropdowns[1]}"></span> -->
						</a>
						<ul class="dropdown-menu custom-border academy-dropdown" [class.show]="openDropdowns[1]">
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="products/submission-and-peer-review"
									(click)="toggleMobileView()">Submission and Peer review</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="products/research-integrity"
									(click)="toggleMobileView()">Research integrity</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="products/composition"
									(click)="toggleMobileView()">Composition</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="products/hosting"
									(click)="toggleMobileView()">Hosting</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="products/data-analytics-and-reporting"
									(click)="toggleMobileView()">Data analytics and reporting</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="products/end-to-end-publishing"
									(click)="toggleMobileView()">End-to-end publishing</a>
							</li>
						</ul>
					</li>
					<li class="nav-item nav-item-mobile">
						<a class="nav-link dropdown-toggle sub" (click)="toggleDropdown(2)" role="button">
							Resources
							<fa-icon [icon]="['fas','angle-down']" *ngIf="!openDropdowns[2]"></fa-icon>
							<fa-icon [icon]="['fas','angle-up']" *ngIf="openDropdowns[2]"></fa-icon>
							<!-- <span class="custom-icon" [ngClass]="{'rotate': openDropdowns[2]}"></span> -->
						</a>
						<ul class="dropdown-menu custom-border academy-dropdown" [class.show]="openDropdowns[2]">

							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="resources/onboarding-and-implementation"
									(click)="toggleMobileView()">Onboarding and Implementation</a>
							</li>
							<li class="nav-item nav-item-mobile">
								<a class="dropdown-item" routerLink="resources/client-services"
									(click)="toggleMobileView()">Client services</a>
							</li>
						</ul>
					</li>
					<li class="nav-item nav-item-mobile">
						<a routerLink="news" class="nav-link dropdown-toggle sub" (click)="toggleMobileView()">News</a>
					</li>
					<li class="nav-item nav-item-mobile">
						<button class="btn btn-outline-success me-2" type="button"
							(click)="openModal()">Contact</button>
					</li>
				</ul>
			</div>



		</div>
	</nav>
</div>
<app-contact-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-contact-modal>