import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, PLATFORM_ID, ViewChild, HostListener } from '@angular/core';
import * as reviewData from '../../assets/json/reviews_content.json';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-client-comment',
  templateUrl: './client-comment.component.html',
  styleUrls: ['./client-comment.component.scss']
})
export class ClientCommentComponent implements AfterViewInit, OnDestroy {
  reviewContent: any[] = (reviewData as any).default.slice(0, 5);
  @ViewChild('carouselDiv') carouselDiv!: ElementRef<HTMLDivElement>;
  intervalId: any;
  scrollStep :number|undefined;
  activeIndex = 0;
  autoScroll = true;
  scrollPauseDuration = 5000;
  startX = 0; // For swipe detection

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.startCarousel();
      this.scrollStep= this.carouselDiv.nativeElement.querySelector('.card-gap:first-child')?.scrollWidth;
    }
  }

  startCarousel() {
    // Clear any existing interval to prevent stacking
    this.clearAutoScroll();

    if (this.autoScroll) {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, 10000);
    }
  }

  clearAutoScroll() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  delayForScroll() {
    this.autoScroll = false;
    this.clearAutoScroll(); // Stop any ongoing auto-scroll interval
    setTimeout(() => {
      this.autoScroll = true;
      this.startCarousel(); // Restart auto-scroll after delay
    }, this.scrollPauseDuration);
  }

  nextSlide() {
    this.scrollOneImage(1);
    this.delayForScroll();
  }

  prevSlide() {
    this.scrollOneImage(-1);
    this.delayForScroll();
  }

  scrollOneImage(direction: number) {
    const carousel = this.carouselDiv.nativeElement;

    this.activeIndex = (this.activeIndex + direction + this.reviewContent.length) % this.reviewContent.length;

    setTimeout(() => {
      if (direction === 1) {
        this.appendFirstImage();
      } else if (direction === -1) {
        this.prependLastImage();
      }
      carousel.style.transition = 'none';
      carousel.style.transform = 'translateX(0)';
    }, 500);
    this.scrollStep= this.carouselDiv.nativeElement.querySelector('.card-gap:first-child')?.scrollWidth;
    carousel.style.transition = 'transform 1s ease';
    const translateValue = -(this.scrollStep!+200)! * direction;
    carousel.style.transform = `translateX(${translateValue}px)`;
  }

  selectSlide(index: number) {
    if (index === this.activeIndex) return;

    const direction = index > this.activeIndex ? 1 : -1;
    const diff = Math.abs(index - this.activeIndex);

    for (let i = 0; i < diff; i++) {
      this.scrollOneImage(direction);
    }

    this.activeIndex = index;
    this.delayForScroll();
  }

  appendFirstImage() {
    const carousel = this.carouselDiv.nativeElement;
    const firstItem = carousel.querySelector('.card-gap:first-child');
    if (firstItem) {
      carousel.appendChild(firstItem);
    }
  }

  prependLastImage() {
    const carousel = this.carouselDiv.nativeElement;
    const lastItem = carousel.querySelector('.card-gap:last-child');
    if (lastItem) {
      carousel.insertBefore(lastItem, carousel.firstChild);
      setTimeout(() => {
        carousel.style.transition = 'transform 1s ease';
        carousel.style.transform = 'translateX(0)';
      }, 500);
    }
  }

  // Detect swipe start
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
  }

  // Detect swipe end and change slide
  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    const endX = event.changedTouches[0].clientX;
    const diff = this.startX - endX;

    // If swipe is enough to change slide (threshold = 50px)
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        this.nextSlide(); // Swipe left -> Next slide
      } else {
        this.prevSlide(); // Swipe right -> Previous slide
      }
    }
  }

  ngOnDestroy(): void {
    this.clearAutoScroll();
  }
}
