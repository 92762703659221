import { isPlatformBrowser } from '@angular/common';
import { Component, AfterViewInit, ViewChild, ElementRef, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements AfterViewInit, OnDestroy {
  @ViewChild('carouselDiv') carouselDiv!: ElementRef<HTMLDivElement>;
  intervalId: any;
  scrollStep :number|undefined; // Set to the width of one image

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.startCarousel();
      this.scrollStep= this.carouselDiv.nativeElement.querySelector('a:first-child')?.scrollWidth;
      
      
    }
  }

  startCarousel() {
    this.intervalId = setInterval(() => {
      this.scrollOneImage();
    }, 2000); // Adjust interval for speed
  }

  scrollOneImage() {
    const carousel = this.carouselDiv.nativeElement;

    // Move carousel by one image width
    carousel.style.transition = 'transform 1s ease';
    this.scrollStep= this.carouselDiv.nativeElement.querySelector('a:first-child')?.scrollWidth;
    carousel.style.transform = `translateX(-${this.scrollStep}px)`;

    // After the transition, append the first image to the end
    setTimeout(() => {
      this.appendFirstImage();
      // Reset transform without transition for seamless effect
      carousel.style.transition = 'none';
      carousel.style.transform = 'translateX(0)';
    }, 1000); // Matches transition duration
  }

  appendFirstImage() {
    const carousel = this.carouselDiv.nativeElement;
    const firstImage = carousel.querySelector('a:first-child');
    if (firstImage) {
      carousel.appendChild(firstImage);
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
