import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

export interface ReviewContent {
  client_comment: string;
  client_image: string;
  client_name: string;
  client_designation: string;
}

@Component({
  selector: 'app-client-comment-card',
  templateUrl: './client-comment-card.component.html',
  styleUrls: ['./client-comment-card.component.scss']
})
export class ClientCommentCardComponent {
  @Input() eachReviewContent!: ReviewContent;
  

}
