<section id="banner-section" class="banner-container">
    <!-- <img src="Minimal - 4.png" class="img-fluid grid-image position-absolute" alt=""> -->
    <div class="container-fluid banner-container-fluid pt-5 pb-5 ">
        <div class="row">
            <div class="col-md-12 col-lg-10 banner-content-section">
                <div class="">
                    <div>
                        <h2 class="banner-heading-first">River Valley </h2>
                    </div>
                    <div>
                        <h2 class="banner-heading-first-sub">Technologies</h2>
                    </div>
                </div>
                <div class="banner-heading-container">
                   
                  

                    <div class="heading-with-arrow">
                        <h2 class="banner-heading">Accelerating the Communication of Research</h2>
                        <!-- <img src="Vector (1).png" alt="arrow" class="heading-arrow"> -->
                    </div>
                    <!-- <img src="Vector (2).png" alt="underline" class="heading-underline"> -->
                </div>
                <h2 class="banner-sub-heading mb-0">35 years of publishing innovation</h2>
                <p class="lead ">Empowering publishers with fully customizable, end-to-end solutions that streamline
                    every stage of the publishing lifecycle. Our XML-first platform accelerates research communication while
                    ensuring scalability, accuracy, integrity, and optimised workflows for authors, reviewers and
                    editors.</p>
                <!-- <p class="lead pt-2">
                    Our solutions are fully configurable to any workflow, guaranteed XML-first and
                    deliver comprehensive in-built research integrity checks. The systems are
                    designed to ensure fast and pain-free transition with zero data loss.
                </p> -->
                <div class="button-section">
                    <button class="btn btn-success me-2" type="button" routerLink="about">Read more</button>
                    <button class="btn btn-outline-success btn-white me-2" type="button" (click)="openModal()"  >Book a demo</button>

                </div>
                <!-- <div class="">
                     -->
                <div class="d-flex justify-content-center ">
                    <div class="star-image-container">
                        <!-- <img src="Burst-pucker-2.png" alt=""> -->
                    </div>
                </div>
                <!-- </div> -->
            </div>
            <!-- <div class="col-md-12 col-lg-4 d-flex justify-content-end ">
                <div class="banner-box">
                    <img src="Editorial-commision-pana1.png" alt="" class="img-fluid banner-image  ">
                </div>
            </div> -->
        </div>
    </div>
    <!-- <div class="sliding-grid-container">
        <img src="Group 11310.png" alt="" class="sliding-grid">
        <img src="Minimal - 4.png" class="img-fluid grid-image1 " alt="">
    </div> -->

</section>
<section class="content-section" id="targetSection">
    <div class="container-fluid">
        <div class="content-heading-with-arrow">
            <h1 class="content-heading">What we do</h1>
            <img src="Vector (3).png" alt="arrow" class="content-heading-arrow">
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 full-height">
                <div class="card card-green wwd-card-green">
                    <div class="card-heading">Award-Winning peer review system</div>
                    <!-- <div class="card-img">

                    </div> -->
                    <div class="card-body ">

                        <p class="card-text  ">ReView 3.0 is our XML-based peer review platform that streamlines the
                            submission and review process for authors, reviewers, and publishers. With integrated
                            production tracking and powerful data analytics, publishers benefit from a real-time,
                            portfolio-wide view that simplifies workflow management.</p>
                    </div>

                    <a class="btn btn-success learn-more learn-more-btn-green" type="button"
                        routerLink="products/submission-&-peer-review"> Learn more <fa-icon
                            [icon]="['fas', 'right-long']" class="arrow-font ps-2"></fa-icon></a>

                    <!-- <div class="card-body mt-4">
                        
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 full-height">
                <div class="card card-green wwd-card-orange">
                    <div class="card-heading">
                        In-built research integrity checks
                    </div>
                    <!-- <div class="card-img">

                    </div> -->
                    <div class="card-body">

                        <p class="card-text  ">The RVT Research integrity checker, developed with industry experts,
                            automatically flags potential issues at submission, helping teams quickly pinpoint concerns
                            and reduce time spent on manual checks. </p>
                    </div>

                    <a class="btn btn-success learn-more learn-more-btn-orange" type="button"
                        routerLink="products/research-integrity"> Learn more <fa-icon [icon]="['fas', 'right-long']"
                            class="arrow-font ps-2"></fa-icon></a>

                    <!-- <div class="card-body mt-4">
                        
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 full-height">
                <div class="card card-green wwd-card-red">
                    <div class="card-heading">
                        Automated format conversion
                    </div>
                    <!-- <div class="card-img">

                    </div> -->
                    <div class="card-body">

                        <p class="card-text  ">Our unique cloud-based production system generates XML from authors’ Word or
                            LaTeX files, provides online proofing and instantly creates PDFs directly from XML. The
                            system delivers full version history, and can handle multiple versions &ndash; all on a single
                            platform, speeding up publication.</p>
                    </div>

                    <a class="btn btn-success learn-more learn-more-btn-red" routerLink="products/composition"> Learn
                        more <fa-icon [icon]="['fas', 'right-long']" class="arrow-font ps-2"></fa-icon></a>
                    <!-- <div class="card-body mt-4">
                        
                    </div> -->
                </div>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-12 full-height">
                <div class="card card-green wwd-card-cyan">
                    <div class="card-heading">
                        Collaborative authoring
                    </div>
                    <!-- <div class="card-img">

                    </div> -->
                    <div class="card-body">

                        <p class="card-text  ">Our authoring platform simplifies and enhances collaboration and moves
                            XML generation upstream. Authors and publishers benefit from an accelerated, transparent
                            process whilst maintaining research integrity.</p>
                    </div>

                    <a class="btn btn-success learn-more learn-more-btn-cyan"> Learn more <fa-icon
                            [icon]="['fas', 'right-long']" class="arrow-font ps-2"></fa-icon></a>
                    <!-- <div class="card-body mt-4">
                        
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 full-height">
                <div class="card card-green wwd-card-purple">
                    <div class="card-heading">
                        Flexible, accessible publishing platform
                    </div>
                    <!-- <div class="card-img">

                    </div> -->
                    <div class="card-body">

                        <p class="card-text  ">Our adaptable publishing platform supports multiple content types
                            providing multilingual and accessible views for readers. Built for fast set up and smooth
                            ingestion of publisher’s XML, it’s a scalable solution for all publishing needs.</p>
                    </div>

                    <a class="btn btn-success learn-more learn-more-btn-purple" routerLink="products/hosting"> Learn
                        more<fa-icon [icon]="['fas', 'right-long']" class="arrow-font ps-2"></fa-icon></a>
                    <!-- <div class="card-body mt-4">
                        
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 full-height">
                <div class="card card-green wwd-card-aqua">
                    <div class="card-heading">
                        Data analytics and reporting
                    </div>
                    <!-- <div class="card-img">

                    </div> -->
                    <div class="card-body">

                        <p class="card-text  ">Instant overview of the publisher’s entire portfolio provides powerful
                            insights with comprehensive analytics and reporting. Track the entire publishing workflow
                            and make data-driven decisions to enhance efficiency and impact.</p>
                    </div>

                    <a class="btn btn-success learn-more learn-more-btn-aqua"
                        routerLink="products/data-analytics-and-reporting"> Learn more <fa-icon
                            [icon]="['fas', 'right-long']" class="arrow-font ps-2"></fa-icon></a>
                    <!-- <div class="card-body mt-4">
                        
                    </div> -->
                </div>
            </div>

        </div>
    </div>

</section>
<section class="end-to-end-section">
    <div class="container-fluid position-relative pb-5">
        <div class="d-flex justify-content-between align-items-center position-relative">
            <img src="Minimal - 2.png" alt="" class="wave-image img-fluid position-absolute">
            <div class="text-center w-100 position-relative">
                <h1 class="end-to-end-heading">End to end publishing solution</h1>
                <img src="Vector (3).png" alt="" class="heading-underline-1">
            </div>
            <img src="Minimal - 7.png" alt="" class="img-fluid grid-image grid-img-end position-absolute">
        </div>
        <div class="content-container d-flex justify-content-center">
            <img src="Group 11324.jpg" alt="" class="img-fluid">
        </div>
        <!-- <div class="position-relative">
            <img src="Minimal - 6.png" alt="" class="img-fluid grid-small">
            <img src="Modern - 2.png" alt="" class="modern-img">
        </div> -->
    </div>
</section>

<section class="technical-phylo-section">
    <div class="container-fluid ">
        <div class="text-center w-100 position-relative">
            <h1 class="end-to-end-heading"> Technical philosophy</h1>
            <img src="Vector (3).png" alt="" class="heading-underline-2">
        </div>
        <div class="technical-content-section">
            <!---------------Old desing-->
            <!-- <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pb-xs-5 pb-sm-5 pb-md-5 pb-lg-0 pb-xl-0">
                    <div class="card card-outline">
                        <div class="card-body card-content ">
                            <div class="content-card-section">
                                <h3 class="technical-heading">Award winning solutions</h3>
                                <p class="technical-sub-content">We have won multiple innovation awards for our systems.
                                </p>
                                <div class="d-flex flex-row align-items-center award-logo">
                                    <img src="logo3.png" alt="" class="card-image">
                                    <p class="image-content mb-0">ISMTE Innovation award</p>
                                </div>
                                <div class="d-flex flex-row align-items-center mt-2  award-logo">
                                    <img src="logo_print.png" alt="" class="card-image">
                                    <p class="image-content mb-0">ALPSP Innovation award</p>
                                </div>
                            </div>
                            <div class="d-flex mt-4">
                                <button class="btn btn-success small-button me-2" type="button">Read more</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="card card-outline">
                        <div class="card-body card-content ">
                            <div class="content-card-section">
                                <h3 class="technical-heading">Full support</h3>
                                <div class="card-content-section">
                                    <p class="technical-sub-content">Fast and <span class="bolding">expert 24x7
                                            support</span>
                                        with
                                        <span class="bolding">new feature requests delivered quickly.</span>
                                    </p>
                                    <p class="technical-sub-content">Fast set up and pain-free transition with zero data
                                        loss.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex mt-4">
                                <button class="btn btn-success small-button me-2" type="button">Read more</button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="row mt-5 pt-md-2 pt-lg-3">
                <div class="col-12">
                    <div class="card card-outline">
                        <div class="card-body card-content ">
                            <div class="content-card-section">
                                <h3 class="technical-heading">Future-proof systems</h3>
                                <p class="technical-sub-content"><span class="bolding">Open source technology </span>
                                    – We have produced our platforms using open source software.
                                    This means our publishing systems are,
                                    <span class="bolding">independent of any proprietary 3rd party systems (including
                                        Adobe)</span>,
                                    giving security to us as well as our clients.

                                </p>
                                <p class="technical-sub-content"> <span class="bolding">Modular publishing suite</span>
                                </p>
                                <p class="technical-sub-content">Our end to end publishing solution is modular. This
                                    means publishers can adopt the systems
                                    for each stage of the publishing process as and when
                                    appropriate for their business. New stages can be seamlessly added or removed at any
                                    time.
                                </p>
                            </div>
                            <div class="d-flex mt-4">
                                <button class="btn btn-success small-button me-2" type="button">Read more</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div> -->
            <!--------------------------------------------------------->
            <!--------------------------------------New Design-->
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pb-xs-5 pb-sm-5 pb-md-5 pb-lg-0 pb-xl-0">
                    <div class="card card-outline wwd-card-green">
                        <div class="card-body card-content ">
                            <div class="content-card-section">
                                <h3 class="technical-heading">XML-first structure for longevity and flexibility</h3>
                                <p class="technical-sub-content">Single source publishing is at the heart of River
                                    Valley’s processes, with an absolute guarantee of fidelity between
                                     formats. This means publishers can publish their XML and nothing else and PDFs
                                    can be created on the fly and in real time, as requested by readers. </p>

                            </div>


                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pb-xs-5 pb-sm-5 pb-md-5 pb-lg-0 pb-xl-0">
                    <div class="card card-outline wwd-card-orange">
                        <div class="card-body card-content ">
                            <div class="content-card-section">
                                <h3 class="technical-heading">Independent, future-proof solutions</h3>
                                <div class="card-content-section">
                                    <p class="technical-sub-content">
                                        Our platforms are built  from the ground up, with zero dependency on
                                        proprietary third-party software. This includes our XML-based pagination engine,
                                        created with open-source software, ensuring future-proofing and security for our
                                        clients.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
            <div class="row pt-4">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pb-xs-5 pb-sm-5 pb-md-5 pb-lg-0 pb-xl-0">
                    <div class="card card-outline wwd-card-purple">
                        <div class="card-body card-content ">
                            <div class="content-card-section">
                                <h3 class="technical-heading">Designed for accessibility </h3>
                                <div class="card-content-section">
                                    <p class="technical-sub-content">
                                        We believe in <em>“born accessible”</em> content. Our platforms generate content in
                                        multiple formats, including on-demand PDFs  optimized for accessibility—serving
                                        users with visual impairments or dyslexia.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pb-xs-5 pb-sm-5 pb-md-5 pb-lg-0 pb-xl-0">
                    <div class="card card-outline wwd-card-red">
                        <div class="card-body card-content ">
                            <div class="content-card-section">
                                <h3 class="technical-heading">User-friendly and mobile-ready</h3>
                                <div class="card-content-section">
                                    <p class="technical-sub-content">
                                        All of our platforms are designed to be intuitive, responsive, and fully
                                        optimized for tablets and mobile devices. Whether users are on desktop or
                                        mobile, our systems provide a seamless, easy-to-navigate experience.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
            <!-------------------------------------END OF NEW DESIGN--------------------------------------->

        </div>
    </div>
</section>
<div class="client-partners">
    <div class="partners-section">
        <div class="d-flex justify-content-center">
            <h2 class="our-client-heading">Clients and Partners</h2>
        </div>
        <app-image-carousel></app-image-carousel>
    </div>
    <app-client-comment></app-client-comment>
</div>
<app-contact-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-contact-modal>