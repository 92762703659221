import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ContactModalComponent } from '../../contact-modal/contact-modal.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
	@ViewChild('navbarContainer') navbarContainer!: ElementRef<HTMLElement>;
	activeDropdown: string | null = null;
	mobileView = false;
	isMenuOpen = false;
	menuStates: { [key: string]: boolean } = {};
	screenWidth: number = 0;
	isModalOpen = false;
	openDropdowns: boolean[] = [false, false, false, false]; // Adjust size based on your main dropdowns
	// Array to track the open state of sub-dropdowns
	openSubDropdowns: boolean[][] = [[], [], [], []]
	constructor(
		private renderer: Renderer2, private el: ElementRef,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {

	}
	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		if (isPlatformBrowser(this.platformId)) {
			this.screenWidth = window.innerWidth;
			if (this.screenWidth >= 992 && this.isMenuOpen) {
				this.mobileView = false;
				this.isMenuOpen = false;
			}
		}
	}
	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.screenWidth = window.innerWidth;
			window.addEventListener('scroll', this.onScroll.bind(this));
		}
	}
	showSubmenuItems(menu: string) {
		this.activeDropdown = menu;
	}
	hideSubmenuItems() {
		this.activeDropdown = null;
	}
	// isMenuOpenMenu(menu: string): boolean {
	// 	// return this.dropdownService.isOpen(menu);
	// }
	toggleDropdown(index: number): void {
		this.openDropdowns[index] = !this.openDropdowns[index]; // Toggle the main dropdown state

		// Close all other main dropdowns
		this.openDropdowns.forEach((_, i) => {
			if (i !== index) {
				this.openDropdowns[i] = false;
				this.openSubDropdowns[i] = []; // Close any sub-dropdowns when a main dropdown is closed
			}
		});
	}

	toggleSubDropdown(mainIndex: number, subIndex: number): void {
		// Toggle the specified sub-dropdown
		this.openSubDropdowns[mainIndex][subIndex] = !this.openSubDropdowns[mainIndex][subIndex];

		// Close all other sub-dropdowns in the same main dropdown
		this.openSubDropdowns[mainIndex].forEach((_, i) => {
			if (i !== subIndex) {
				this.openSubDropdowns[mainIndex][i] = false; // Close other sub-dropdowns
			}
		});
	}

	isSubDropdownOpen(mainIndex: number, subIndex: number): boolean {
		return this.openSubDropdowns[mainIndex][subIndex];
	}
	closeOtherMenu(menu: string) {
		for (let key in this.menuStates) {
			this.menuStates[key] = key === menu;
		}
	}
	closeMenu(menu: string) {
		this.menuStates[menu] = false;
		// this.resetSearchContext();
		// setTimeout(() => {
		// 	this.dropdownService.closeMenu(menu);
		// }, 200);


	}

	cancelCloseMenu(menu: string) {
		// this.menuHeadingLoading=true;
		// this.menuContentLoading=true;
		this.menuStates[menu] = true;
		// this.dropdownService.cancelCloseMenu(menu);
	}
	toggleMobileView() {
		this.isMenuOpen = !this.isMenuOpen;
		this.mobileView = !this.mobileView;
		if (!this.isMenuOpen) {
			// this.menus.forEach(e => {
			// this.menuStates[e.identifier] = false;
			// });
		}
		if (this.mobileView) {
			this.renderer.listen('window', 'click', this.detectOutsideClick.bind(this));
		}

	}
	detectOutsideClick(event: MouseEvent) {
		const clickedInside = this.el.nativeElement.contains(event.target);
		if (!clickedInside) {
			this.mobileView = false;
			this.isMenuOpen = false;
		}
	}
	openMenu(menu: string) {
		if (!this.menuStates[menu]) this.menuStates[menu] = true;
		// this.dropdownService.openMenu(menu);


	}
	onScroll() {
		const logo = document.querySelector('#logo');
		if (window.scrollY > 0) {
			this.navbarContainer.nativeElement.classList.add('scroll-shadow');
			this.navbarContainer.nativeElement.classList.add('sticky-container');

			//   if  (window.scrollY > 637) {
			// logo?.setAttribute('style', ' bottom:-2px; transition: height 0.3s ease;    height: 58px;');

			//   } 

		} else {
			//  if(this.screenWidth>992) this.joinBtn.nativeElement.style.display = 'block';
			logo?.setAttribute('style', 'height:auto');
			this.navbarContainer.nativeElement.classList.remove('scroll-shadow');
			this.navbarContainer.nativeElement.classList.remove('sticky-container');

		}
		// if(this.screenWidth>992) this.joinBtn.nativeElement.style.display = 'block';
		// else this.joinBtn.nativeElement.style.display = 'none !important';

	}
	openModal() {
		this.isModalOpen = true;
	}
	closeJoinModal() {
		this.isModalOpen = false;
	}

}
