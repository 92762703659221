<section id="page-section" class="page-content-container">
    <div class="container-fluid first-container">
        <div class="content-data-container">
            <div *ngFor="let data of content">
                <ng-container *ngIf="data.page_identifier===lastSegment">
                    <h2 class="page_heading pb-1">{{data.page_heading}}</h2>
                    <div [innerHTML]="data.page_content" class="content"></div>
                    <!-- Use innerHTML for rendering HTML content -->
                    <div *ngIf="urlSegments[0].path === 'products'">
                        <button type="button" class="btn btn-success btn-book-a-demo" (click)="openModal()">Book a
                            demo</button>
                    </div>

                </ng-container>
            </div>
            <div *ngIf="lastSegment === 'news'">
                <h2 class="page_heading pb-1">News</h2>
                <div class="news-content" *ngFor="let data of newsContent; let i = index">
                    <h3 class="news-content-title"> <a [routerLink]="['/news', data.news_link]"
                            [innerHTML]="data.news_title"></a></h3>
                    <div class="news-content-container" [innerHTML]="data.news_content"></div>
                    <a [routerLink]="['/news', data.news_link]" class="read-more btn btn-success btn-book-a-demo">Read
                        More</a>
                </div>
            </div>
            <div *ngIf="lastSegment==='our-clients-and-partners'">
                <h2 class="page_heading pb-1">Our Clients and Partners</h2>
                <div>
                    <p>
                        We are proud to have collaborated with leading STM publishers, learned societies, research
                        institutions, NGOs and industry bodies to deliver reliable, scalable publishing solutions.Trusted for our dedicated approach, we
                        are committed to supporting each client’s unique needs.
                    </p>
                </div>
                <div class="image-row mt-3 mb-4">
                    <div class="clients-bar-wrapper aos-init aos-animate" data-aos="fade-up">
                        <app-image-carousel></app-image-carousel>

                    </div>
                </div>
                <div class="  image-row mt-3 mb-4" *ngIf="notShownContent">
                    <ul class="clients-bar-wrapper aos-init aos-animate" data-aos="fade-up">
                        <li class="clients-bar-item">
                            <a href="https://www.theiet.org/" target="_blank">
                                <img src="clients_and_partners/iet-e1630557368615.jpg" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.iop.org/" target="_blank">
                                <img src="clients_and_partners/iop.jpg">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.nature.com/" target="_blank">
                                <img src="clients_and_partners/npg_logo.png">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.spherestandards.org/" target="_blank">
                                <img src="clients_and_partners/sphere.png">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.cambridge.org/" target="_blank">
                                <img src="clients_and_partners/cambridge.png">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://global.oup.com/?cc=gb" target="_blank">
                                <img src="clients_and_partners/oxford-university-press-1.svg">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.degruyter.com/" target="_blank">
                                <img src="clients_and_partners/imgres-300x161.png">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://peerj.com/" target="_blank">
                                <img src="clients_and_partners/peerJ_logo_transparent.png">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.stm-assoc.org/" target="_blank">
                                <img src="clients_and_partners/stm-logo.jpg">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.sspnet.org" target="_blank">
                                <img src="clients_and_partners/unnamed.png">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.iospress.nl/" target="_blank">
                                <img src="clients_and_partners/iospress_fb-150x150.jpg">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.force11.org/" target="_blank">
                                <img src="clients_and_partners/force11-Logo-1.png" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.imaging.org/site/ist" target="_blank">
                                <img src="clients_and_partners/cABFqZog_400x400-150x150.png">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.aps.org/" target="_blank">
                                <img src="clients_and_partners/OT0027H-300x184-1.jpg">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.elsevier.com/" target="_blank">
                                <img src="clients_and_partners/Elsevier.svg">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://sparceurope.org" target="_blank">
                                <img src="clients_and_partners/sparceurope-logo-1.png">
                            </a>
                        </li>
                        <li class="clients-bar-item"><a href="https://oaspa.org/" target="_blank">
                                <img src="clients_and_partners/oaspa-logo.svg"></a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.wiley.com/en-gb" target="_blank">
                                <img src="clients_and_partners/wiley-Logo2.png" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.crossref.org/" target="_blank">
                                <img src="clients_and_partners/crossref-logo-200.svg">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.qscience.com/" target="_blank">
                                <img src="clients_and_partners/qscience-logo.png" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.ice.org.uk/" target="_blank">
                                <img src="clients_and_partners/ICE-logo.jpg" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://en.genomics.cn/en-periodical-1818.html" target="_blank">
                                <img src="clients_and_partners/The_2012_logo_of_GigaScience_Journal.png" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://leprosyreview.org/" target="_blank">
                                <img src="clients_and_partners/ifc.jpg" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://lameteorologie.fr/" target="_blank">
                                <img src="clients_and_partners/SMF-METEO-et-CLIMAT_texte-noir.jpg" alt="">
                            </a>
                        </li>
                        <li class="clients-bar-item">
                            <a href="https://www.intechopen.com/" target="_blank">
                                <img src="clients_and_partners/iInTec-150x150.png" alt="">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class=" pb-5">
                    <div class="">
                        <div class="image-row  row g-3">
                            <div class=" col-xl-3 col-lg-3 col-md-3 col-sm-4  d-flex justify-content-center">
                                <a href="https://www.crossref.org/" target="_blank "
                                    class="w-100 d-flex justify-content-center"><img src="clients_and_partners/crossref-logo-200.svg"
                                        alt="Image 1"></a>
                            </div>
                            <div class=" col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-center">
                                <a href="https://www.qscience.com/" target="_blank"
                                    class="w-100 d-flex justify-content-center"><img src="clients_and_partners/qscience-logo.png"
                                        alt="Image 2"></a>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4  d-flex justify-content-center">
                                <a href="https://www.ice.org.uk/" target="_blank "
                                    class="w-100 d-flex justify-content-center"> <img src="clients_and_partners/ICE-logo.jpg"
                                        alt="Image 3"></a>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-center">
                                <a href="https://en.genomics.cn/en-periodical-1818.html" target="_blank"
                                    class="w-100 d-flex justify-content-center">
                                    <img src="clients_and_partners/The_2012_logo_of_GigaScience_Journal.png" alt="Image 4"
                                        class="gigascienec">
                                </a>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-center">
                                <a href="https://www.theiet.org/" target="_blank"
                                    class="w-100 d-flex justify-content-center"> <img src="clients_and_partners/iet-e1630557368615.jpg"
                                        alt="Image 5"></a>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-center">
                                <a href="https://www.iop.org/" target="_blank"
                                    class="w-100 d-flex justify-content-center"> <img src="clients_and_partners/iop.jpg" alt="Image 6"></a>

                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-center">
                                <a href="https://sparceurope.org/" target="_blank"
                                    class="w-100 d-flex justify-content-center"> <img src="clients_and_partners/sparceurope-logo-1.png"
                                        alt="Image 7"></a>


                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 d-flex justify-content-center">
                                <a href="https://sparceurope.org/" target="_blank"
                                    class="w-100 d-flex justify-content-center"> <img src="clients_and_partners/sparceurope-logo-1.png"
                                        alt="Image 7"></a>


                            </div>


                        </div>
                    </div>
                </div> -->
            <div class="row d-flex justify-content-center" *ngIf="lastSegment==='our-clients-and-partners'">
                <h4 class=" pb-2">What they say about us</h4>
                <div class=" mb-3 col-xs-12 col-ms-6 col-sm-6 col-md-6 col-lg-4 " *ngFor="let data of reviewContent">
                    <div class="card card-testimonal">
                        <div class="card-body">
                            <div class="d-flex justify-content-center align-items-center flex-column">

                                <div>
                                    <a href="{{data.client_link}}" target="_blank">
                                        <img style="width: 100px;" src="clients_and_partners/{{data.client_image}}"
                                            alt="">
                                    </a>
                                </div>
                                <div>
                                    <p class="bold client-name">{{data.client_name}}</p>
                                </div>
                                <div>
                                    <p class="bold client-des">{{data.client_designation}}</p>
                                </div>
                                <div>
                                    <p class="comment-text ">{{data.client_comment}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</section>
<app-contact-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-contact-modal>