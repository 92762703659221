export const environment = {
    production: true,
    clientName: "RVT",
    siteUrl: "https://rivervalley.io/",
    apiUrl: "https://rivervalley.io/admin/backend/",
    applicationMode: "Staging",
    showAppMode: true,
    recaptcha: {
        siteKey: '6Lc1qIIqAAAAAPZLboIYDnC823vqiVtmr5yOn9Zd',
      },
};
