<div class="carousel-wrapper">
	<div class="carousel-container">
		<div class="image-row" #carouselDiv>
			<a href="https://www.theiet.org/" target="_blank">
				<img src="clients_and_partners/iet-e1630557368615.jpg" alt="">
			</a>
			<a href="https://www.iop.org/" target="_blank">
				<img src="clients_and_partners/iop.jpg">
			</a>
			<a href="https://www.nature.com/" target="_blank">
				<img src="clients_and_partners/npg_logo.png">
			</a>
			<a href="https://www.spherestandards.org/" target="_blank">
				<img src="clients_and_partners/sphere.png">
			</a>
			<a href="https://www.cambridge.org/" target="_blank">
				<img src="clients_and_partners/cambridge.png">
			</a>
			<a href="https://global.oup.com/?cc=gb" target="_blank">
				<img src="clients_and_partners/oxford-university-press-1.svg">
			</a>
			<a href="https://www.degruyter.com/" target="_blank">
				<img src="clients_and_partners/imgres-300x161.png"></a>

			<a href="https://peerj.com/" target="_blank">
				<img src="clients_and_partners/peerJ_logo_transparent.png">
			</a>

			<a href="https://www.stm-assoc.org/" target="_blank">
				<img src="clients_and_partners/stm-logo.jpg">
			</a>
			<a href="https://www.sspnet.org" target="_blank">
				<img src="clients_and_partners/unnamed.png">
			</a>
			<a href="https://www.iospress.nl/" target="_blank">
				<img src="clients_and_partners/iospress_fb-150x150.jpg">
			</a>
			<a href="https://www.force11.org/" target="_blank">
				<img src="clients_and_partners/force11-Logo-1.png" alt="">
			</a>
			<a href="https://www.imaging.org/site/ist" target="_blank">
				<img src="clients_and_partners/cABFqZog_400x400-150x150.png">
			</a>
			<a href="https://www.aps.org/" target="_blank">
				<img src="clients_and_partners/OT0027H-300x184-1.jpg">
			</a>
			<a href="https://www.elsevier.com/" target="_blank">
				<img src="clients_and_partners/Elsevier.svg">
			</a>
			<a href="https://sparceurope.org" target="_blank">
				<img src="clients_and_partners/sparceurope-logo-1.png">
			</a>
			<a href="https://www.wiley.com/en-gb" target="_blank">
				<img src="clients_and_partners/wiley-Logo2.png" alt="">
			</a>
			<a href="https://www.crossref.org/" target="_blank">
				<img src="clients_and_partners/crossref-logo-200.svg">
			</a>
			<a href="https://www.qscience.com/" target="_blank">
				<img src="clients_and_partners/qscience-logo.png" alt="">
			</a>
			<a href="https://www.ice.org.uk/" target="_blank">
				<img src="clients_and_partners/ICE-logo.jpg" alt="">
			</a>
			<a href="https://en.genomics.cn/en-periodical-1818.html" target="_blank">
				<img src="clients_and_partners/The_2012_logo_of_GigaScience_Journal.png" alt="">
			</a>
			<a href="https://leprosyreview.org/" target="_blank">
				<img src="clients_and_partners/ifc.jpg" alt="">
			</a>
			<a href="https://lameteorologie.fr/" target="_blank">
				<img src="clients_and_partners/SMF-METEO-et-CLIMAT_texte-noir.jpg" alt="">
			</a>

			<a href="https://www.intechopen.com/" target="_blank">
				<img src="clients_and_partners/iInTec-150x150.png" alt="">
			</a>

		</div>
	</div>
</div>