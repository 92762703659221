<a routerLink="about/our-clients-and-partners" class="text-decoration-none">
    <div class="card">
        <div class="card-body">
            <h1 class="card-title"><fa-icon [icon]="['fas','quote-left']" class="quote-left"></fa-icon></h1>
            <h6 class="card-subtitle mb-2 text-body-secondary line-clamp">
                "{{eachReviewContent.client_comment}}"
            </h6>
            <div class="d-flex pt-3 clent-container">
                <div class="image-container">
                    <img src="clients_and_partners/{{eachReviewContent.client_image}}" alt="" class="image-logo">
                </div>
                <div class="ps-2 pt-2">
                    <span class="comment-by">{{eachReviewContent.client_name}}</span>
                    <p class="mb-0 text-body-secondary line-clamp-2">{{eachReviewContent.client_designation}}</p>
                </div>
            </div>
        </div>
    </div>
</a>