import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from '../config/config';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }
  contactRVT(data:any){
 
    return this.http.post<any>(env.siteApiUrl + "web/contact-rvt",data);
  
    }
}
