<div class="modal-backdrop"></div>
<div class="modal-container">
  <div class="modal-content">
    <div class="heading">
      <h2>Contact</h2>
      <!-- <hr class="hr hr--color hr--lg"> -->
    </div>

    <p class="mb-4">We would love to hear from you. Please fill in the contact form below and give as much detail as you
      can
      regarding your interest.</p>
    <div *ngIf="success; else failed">
      <div class="d-flex justify-content-center mt-4"><fa-icon [icon]="['fas', 'check-circle']"
          class="success-icon pt-2"></fa-icon></div>
      <div class="d-flex align-items-center justify-content-center text-center" role="alert">
        <div class="success-msg">
          <div class="pb-3"><strong>Thank you!</strong></div>
          Your query has been successfully submitted. We will contact you shortly.
        </div>

      </div>
      <div class="d-flex justify-content-center ">
        <p class="text-center"> <a class="text-decoration-none " (click)="closeModal()">Close</a>
        </p>
      </div>

    </div>
    <ng-template #failed>
      <div class="alert alert-danger d-flex align-items-center" *ngIf="falied" role="alert">
        <fa-icon [icon]="['fas', 'times-circle']" class="failed-icon"></fa-icon>
        <div>Failed to add.</div>
      </div>
    </ng-template>
    <form [formGroup]="contactmodalForm" (ngSubmit)="onSubmit('submit')" *ngIf="!success">
      <div class="form-row">
        <div class="form-group">
          <label for="firstName">Name</label>
          <input type="text" id="NAME" formControlName="NAME" placeholder="Enter your  name*" class="form-control">
          <div *ngIf="contactmodalForm.get('NAME')?.invalid && (contactmodalForm.get('NAME')?.touched || submitted)"
            class="error-message">
            <!-- Check for 'required' error -->
            <div *ngIf="contactmodalForm.get('NAME')?.errors?.['required']">
              {{ validationMessages.NAME.required }}
            </div>
            <!-- Check for 'pattern' error -->
            <div *ngIf="contactmodalForm.get('NAME')?.errors?.['pattern']">
              {{ validationMessages.NAME.pattern }}
            </div>
          </div>

        </div>


        <!-- <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" id="LNAME" formControlName="LNAME" placeholder="Enter your last name*"
            class="form-control">
          <div *ngIf="contactmodalForm.get('LNAME')?.invalid && (contactmodalForm.get('LNAME')?.touched || submitted)"
            class="error-message">
            Last Name is required.
          </div>
        </div> -->
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="EMAIL" formControlName="EMAIL" placeholder="Enter your email*" class="form-control">
        <div *ngIf="contactmodalForm.get('EMAIL')?.invalid && (contactmodalForm.get('EMAIL')?.touched || submitted)"
          class="error-message">
          <span
            *ngIf="contactmodalForm.get('EMAIL')?.hasError('required') || contactmodalForm.get('EMAIL')?.hasError('pattern')">A
            valid email is required.</span>
          <!-- <span *ngIf="contactmodalForm.get('EMAIL')?.errors?.['emailTaken']">This email has already been used.</span> -->
        </div>
      </div>


      <div class="form-group">
        <label for="socialMedia">Organisation</label>
        <input type="text" id="ORGANISATION" formControlName="ORGANISATION" placeholder="Enter your organisation*"
          class="form-control">
        <div
          *ngIf="contactmodalForm.get('ORGANISATION')?.invalid && (contactmodalForm.get('ORGANISATION')?.touched || submitted)"
          class="error-message">
          <span *ngIf="contactmodalForm.get('ORGANISATION')?.errors?.['required']">Organisation is required.</span>
          <!-- <span *ngIf="contactmodalForm.get('EMAIL')?.errors?.['emailTaken']">This email has already been used.</span> -->
        </div>
      </div>
      <div class="form-group">
        <label for="query">Query</label>
        <textarea type="text" id="QUERY" formControlName="QUERY" placeholder="Enter your queries*"
          class="form-control query-textarea"></textarea>
        <div *ngIf="contactmodalForm.get('QUERY')?.invalid && (contactmodalForm.get('QUERY')?.touched || submitted)"
          class="error-message">
          <span *ngIf="contactmodalForm.get('QUERY')?.errors?.['required']">Queriess is required.</span>
          <!-- <span *ngIf="contactmodalForm.get('EMAIL')?.errors?.['emailTaken']">This email has already been used.</span> -->
        </div>
      </div>
      <div class="form-group">
        <label for="product_demo">Product demo required</label>
        <select id="PRODUCT_DEMO" formControlName="PRODUCT_DEMO" class="form-select">
          <option value="" disabled selected class="placeholder">Select product demo required*</option>
          <option *ngFor="let eachproducts of products" [value]="eachproducts.toLocaleLowerCase()"
            [disabled]="eachproducts.toLowerCase() !== this.matchingProduct.toLowerCase() && matchingProduct!==''">{{
            eachproducts }}</option>
        </select>
        <div
          *ngIf="contactmodalForm.get('PRODUCT_DEMO')?.invalid && (contactmodalForm.get('PRODUCT_DEMO')?.touched || submitted)"
          class="error-message">
          <span *ngIf="contactmodalForm.get('PRODUCT_DEMO')?.errors?.['required']">Product demo is required.</span>

        </div>
      </div>
      <!-- <re-captcha [siteKey]="siteKey"> -->
      <!-- [size]="'invisible'" -->
      <!-- </re-captcha> -->

      <div class="modal-actions d-flex flex-column mt-5">
        <button type="submit" class="btn-submit" [disabled]="contactmodalForm.invalid && contactmodalForm.touched"
          [attr.data-callback]="'contactmodalForm'" [attr.data-badge]="'inline'" [attr.data-sitekey]="siteKey">
          <div *ngIf="contactmodalForm.valid && submitted && (!failed || !success)" class="spinner-border text-light"
            role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span *ngIf="((!submitted && (failed || success)) || contactmodalForm.invalid)"> Send</span>
        </button>
        <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
      </div>
    </form>


  </div>
</div>