import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageContentComponent } from './page-content/page-content.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: PageContentComponent },
  { path: 'about/our-people', component: PageContentComponent},
  { path: 'about/careers', component: PageContentComponent},
  { path: 'about/our-clients-and-partners', component: PageContentComponent},
  { path: 'products/submission-and-peer-review', component: PageContentComponent},
  { path: 'products/research-integrity', component: PageContentComponent},
  { path: 'products/composition', component: PageContentComponent},
  { path: 'products/hosting', component: PageContentComponent},
  { path: 'products/data-analytics-and-reporting', component: PageContentComponent},
  { path: 'products/end-to-end-publishing', component: PageContentComponent},
  { path: 'resources/onboarding-and-implementation', component: PageContentComponent},
  { path: 'resources/client-services', component: PageContentComponent},
  { path: 'news', component: PageContentComponent},
  { path: 'news/:title', component: NewsDetailsComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [ 
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
