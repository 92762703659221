import { Component, OnInit } from '@angular/core';
import * as contentData from '../../assets/json/page_content.json';
import { ActivatedRoute } from '@angular/router';
import * as newsData from '../../assets/json/news_content.json'
import * as reviewData from '../../assets/json/reviews_content.json'
import * as pageKeyword from '../../assets/json/keywords.json'
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss']
})
export class PageContentComponent implements OnInit {
  content: any[] = (contentData as any).default;
  lastSegment = '';
  newsContent: any[] = (newsData as any).default.news;
  reviewContent: any[] = (reviewData as any).default
  isModalOpen = false;
  urlSegments: any;
  aboutpageKeywordData: any[] = (pageKeyword as any).default.aboutpage;
  productspageKeywordData: any[] = (pageKeyword as any).default.productspage;
  resourcespageKeywordData: any[] = (pageKeyword as any).default.resourcespage;
  notShownContent=false;
  constructor(private route: ActivatedRoute, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.urlSegments = this.route.snapshot.url;
    if (this.urlSegments.length > 0) {
      this.lastSegment = this.urlSegments[this.urlSegments.length - 1].path;
      let title = '';

      if(this.lastSegment=='our-clients-and-partners'){
        title="Clients and partners"
      }
      else if(this.lastSegment=='news')
        {
          title='News'
        }
        else{
          title='River Valley Technologies';
        }
      this.content.forEach(e => {
        if (e.page_identifier === this.lastSegment) {
          title = e.page_name; // Assign the page_name to title
        }
      });

      this.title.setTitle(title);
      if (this.urlSegments[0] == '') {

      }
      else if (this.urlSegments[0] == 'about') {
        
        this.meta.updateTag({ name: 'keywords', content: this.aboutpageKeywordData.join(', ') });

      }
      else if (this.urlSegments[0] == 'products') {
        
        this.meta.updateTag({ name: 'keywords', content: this.productspageKeywordData.join(', ') });

      }
      else if (this.urlSegments[0] == 'resources') {
        this.meta.updateTag({ name: 'keywords', content: this.resourcespageKeywordData.join(', ') });

      }
      else {
        this.meta.removeTag("name='keywords'"); 
      }
      // Set meta tags
      // this.meta.updateTag({ name: 'description', content: 'Read about the latest awards and innovations in ReView.' });
    }
  }
  openModal() {
    this.isModalOpen = true;
  }
  closeJoinModal() {
    this.isModalOpen = false;
  }
  formatted_title(title: string): string {
    return title.replace(/ /g, '-'); // Replace all spaces with hyphens
  }
}
